body {
  background-color: #161826;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.bg-ebox-color {
  background-color: rgba(62, 85, 92, 0.2);
}

.button-color {
  background-color: #99999961;
}

.second-button-color {
  background-color: #B33069 !important;
}

.bg-box-color-ice {
  background-color: #1C2237;
}

.bg-darkblue-ice {
  background-color: #161826 !important;
}

.text-login {
  color: #B33069;
}

.bg-darkblue {
  background-color: #161826;
}

.bg-box-color {
  background-color: #1C2237;
}

.bg-lightblue {
  background-color: #4392F1;
}

.bg-location-hstad {
  background-color: #BCDAFF;
}

.bg-location-gbg {
  background-color: #C7D3FF;
}

.bg-location-sthlm {
  background-color: #8DF6FF;
}

.border-custom {
  border: 0.5px solid #E6E6E633;
}

.border-b-custom {
  border-bottom: 0.5px solid #E6E6E633;
}

.border-t-custom {
  border-top: 0.5px solid #E6E6E633;
}

.text-hstad-blue {
  color: #1B65D7;
}

.text-gbg-purple {
  color: #5E55E4;
}

.text-gbg-purple {
  color: #0093B4;
}

.text-number-blue {
  color: #4392F1;
}

.text-normal-white {
  color: #D9D9D9;
}

.text-oppacity-color {
  color: rgba(217, 217, 217, 0.8); /* RGB values for #D9D9D9 with 80% opacity */
}

.font-albert-sans {
  font-family: 'Albert Sans';
}

@font-face {
  font-family: 'Albert Sans';
  src: url('./assets/fonts/AlbertSans-VariableFont_wght.ttf') format('truetype'),
       url('./assets/fonts/AlbertSans-Italic-VariableFont_wght.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

.my-padding {
  padding-top: 120px;
}

.no-scrollbar::-webkit-scrollbar {
  display: none;  /* Hide scrollbar for Chrome, Safari and Opera */
}

.no-scrollbar {
  -ms-overflow-style: none;  /* Hide scrollbar for IE and Edge */
  scrollbar-width: none;     /* Hide scrollbar for Firefox */
}
